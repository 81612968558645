import { AuthContext } from "context/AuthContext";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  useEffect(() => {
    const unsubscribe = auth.func.onStateChanged((user) => {
      if (user) {
        navigate("/todo");
      } else {
        navigate("/login");
      }
    });
    return () => unsubscribe();
  });
  return <></>;
}

export default Landing;
