import React, { useRef, useState, useContext } from "react";
import classes from "./Login.module.css";
import { AuthContext } from "context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

function Login() {
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const [errorDiv, setErrorDiv] = useState(<></>);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  function loginHandler(e: React.FormEvent) {
    e.preventDefault();
    const emailVal = email.current!.value;
    const passwordVal = password.current!.value;
    authContext.func
      .loginWithEmail(emailVal, passwordVal)
      .then((user) => {
        setErrorDiv(<></>);
        navigate("/todo");
      })
      .catch((err) => {
        setErrorDiv(<div className={classes.error}>Login unsuccessful</div>);
      });
  }

  return (
    <div className="TopContainer">
      <form onSubmit={loginHandler}>
        <h1>Log In</h1>
        <input
          type="email"
          name="email"
          placeholder="Enter email"
          ref={email}
        />
        <input
          type="password"
          name="password"
          placeholder="Enter password"
          ref={password}
        />
        <button type="submit">Log In</button>
        {errorDiv}
      </form>
      <Link className={classes.signup} to="/signup">
        Sign up
      </Link>
    </div>
  );
}

export default Login;
