import { AuthContext } from "context/AuthContext";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import classes from "./Todo.module.css";

function Todo() {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  function logoutHandler(e: React.FormEvent) {
    e.preventDefault();
    authContext.func.logout();
    navigate("/login");
  }

  return (
    <div className="TopContainer">
      <h1>This is to do page</h1>
      <div>
        User: {authContext.user ? authContext.user!.email : "not signed in"}
      </div>
      {authContext.user ? (
        <button className={classes.logout} onClick={logoutHandler}>
          Log out
        </button>
      ) : null}
    </div>
  );
}

export default Todo;
