import { User, Authenticator } from "lib/ports/auth";
import React, { createContext, useEffect, useState } from "react";

type AuthContextValue = {
  user: User | null;
  func: Authenticator;
};
const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

interface Props {
  authAdapter: Authenticator;
  children: React.ReactNode;
}

const AuthProvider: React.FC<Props> = ({ authAdapter, children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  useEffect(() => {
    const unsubscribe = authAdapter.onStateChanged((user: User | null) => {
      setCurrentUser(user);
    });
    return () => unsubscribe();
  }, [authAdapter, children]);

  return (
    <AuthContext.Provider
      value={{
        user: currentUser,
        func: authAdapter,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
