import { useContext, useRef, useState } from "react";
import classes from "./Signup.module.css";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/AuthContext";

function SignUp() {
  const email = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);
  const [errorDiv, setErrorDiv] = useState(<></>);
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  function signUpHandler(e: React.FormEvent) {
    e.preventDefault();
    const emailVal = email.current!.value;
    const passwordVal = password.current!.value;
    authContext.func
      .signupWithEmail(emailVal, passwordVal)
      .then((_) => {
        navigate("/todo");
      })
      .catch((err) => {
        setErrorDiv(<div className={classes.error}>Sign-up unsuccessful</div>);
      });
  }

  return (
    <div className="TopContainer">
      <form onSubmit={signUpHandler}>
        <h1>Sign up</h1>
        <input type="email" placeholder="Enter email" ref={email} />
        <input type="password" placeholder="Enter password" ref={password} />
        <button type="submit">Sign up</button>
        {errorDiv}
      </form>
    </div>
  );
}

export default SignUp;
