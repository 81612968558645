import { initializeApp } from "@firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBpuLftfpUgjysOaVjbhAfSpWm_NrMnCZg",
  authDomain: "todo-app-52198.firebaseapp.com",
  projectId: "todo-app-52198",
  storageBucket: "todo-app-52198.appspot.com",
  messagingSenderId: "608924079611",
  appId: "1:608924079611:web:9a4fa281938c7ecaac675e",
};

const app = initializeApp(firebaseConfig);
export { app, firebaseConfig };
