import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import SignUp from "components/Signup/Signup";
import Login from "./components/Login/Login";
import Todo from "components/Todo/Todo";
import Landing from "components/Landing/Landing";
import { AuthProvider } from "context/AuthContext";
import FirebaseAuthenticator from "lib/adapters/auth/firebase";

const routes = createRoutesFromElements(
  <>
    <Route path="/" element={<Landing />} />
    <Route path="/login" element={<Login />} />
    <Route path="/signup" element={<SignUp />} />
    <Route path="/todo" element={<Todo />} />
  </>
);
export { routes };

function App() {
  return (
    <AuthProvider authAdapter={FirebaseAuthenticator}>
      <RouterProvider router={createBrowserRouter(routes)} />
    </AuthProvider>
  );
}

export default App;
