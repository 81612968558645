import { app } from "lib/adapters/firebase_app";
import { User, Authenticator } from "lib/ports/auth";
import {
  initializeAuth,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  deleteUser,
  signOut,
  onAuthStateChanged,
} from "@firebase/auth";

const auth = initializeAuth(app, {
  persistence: [browserSessionPersistence],
});
export { auth };

const FirebaseAuthenticator: Authenticator = {
  onStateChanged: (
    stateChangedFunc: (user: User | null) => void
  ): (() => void) => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        stateChangedFunc({
          name: user!.displayName,
          email: user!.email,
        });
      } else {
        stateChangedFunc(null);
      }
    });
    return () => unsubscribe();
  },
  getSignedInUser: (): User | null => {
    if (!auth.currentUser) {
      return null;
    } else {
      return {
        name: auth.currentUser!.displayName,
        email: auth.currentUser!.email,
      };
    }
  },
  loginWithEmail: (email: string, password: string): Promise<User> => {
    return signInWithEmailAndPassword(auth, email, password).then(
      (userCred) => {
        return {
          name: userCred.user.displayName,
          email: userCred.user.email,
          isSignedIn: true,
        };
      }
    );
  },
  signupWithEmail: (email: string, password: string): Promise<User> => {
    return createUserWithEmailAndPassword(auth, email, password).then(
      (userCred) => {
        return {
          name: userCred.user.displayName,
          email: userCred.user.email,
          isSignedIn: true,
        };
      }
    );
  },
  deleteSignedInUser: (): Promise<void> => {
    if (!auth.currentUser) {
      return Promise.resolve();
    }

    deleteUser(auth.currentUser!);
    return Promise.resolve();
  },
  logout: (): Promise<void> => {
    return signOut(auth);
  },
};
export default FirebaseAuthenticator;
